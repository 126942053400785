import logo from "./assets/images/logo.png";
import AppStore from "./assets/images/app-store.png";
import PlayStore from "./assets/images/play-store.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Vous utilisez toujours un cahier pour noter ou enregistrer vos
          clients, visiteurs ou crédits ? Rensfi est le registre numérique et
          moderne qu'il vous faut.
        </p>
        <p>Et en plus, l'application marche sans connexion internet.</p>
        <p className="App-link">
          <em>
            Parce que le monde bouge, enregistrez plus efficacement avec Rensfi
            !
          </em>
        </p>
        <div className="StoresContainer">
          <div className="ImageContainer">
            <a
              href="https://play.google.com/store/apps/details?id=tech.cladjen.rensfi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={PlayStore} className="Store" alt="" />
            </a>
          </div>
          <div className="ImageContainer">
            <a
              href="https://apps.apple.com/app/rensfi/id6444734179"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStore} className="Store" alt="" />
            </a>
          </div>
        </div>
        <p className="App-link">
          <a
            href="https://rensfi.cladjen.tech/rensfi-fichier-apk"
            target="_blank"
            rel="noopener noreferrer"
          >
            {""}
            Télécharger le fichier apk{""}
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
